export enum UserQuestStatusType {
  APPROVED = "approved",
  REJECTED = "rejected",
  TX_PENDING = "tx_pending",
  PENDING = "pending",
  SATISFIED = "satisfied",
}

export enum ActionType {
  APPROVE = "approve",
  REJECT = "reject",
  PENDING = "pending",
  REVOKE = "revoke",
}

export enum AdminshipRecordType {
  GAMES = "games",
  COMMUNITIES = "communities",
}

export enum RewardTokenType {
  ERC20 = "erc20",
  ERC721 = "erc721",
  ERC1155 = "erc1155",
}

export enum RewardType {
  TOKEN = "token",
  STICKER_PACK = "sticker_pack",
  DRIP = "drip",
}

export enum ParticipantType {
  STREAMER = "streamer",
  GAMER = "gamer",
}

export enum DistributionStrategy {
  FCFS = "first_come_first_serve",
  GUARANTEED = "guaranteed",
  PRIZE_POOL = "prize_pool",
}

export enum StreamParameter {
  AVERAGE_VIEWERS = "average_viewers",
  PEAK_VIEWERS = "peak_viewers",
  STREAM_DURATION = "stream_duration",
}

export enum QuestObjectiveType {
  AUTO_VERIFY_STREAM = "auto_verify_stream",
  TWITCH_VIEW_STREAM = "view_stream_twitch",
  TWITCH_SUBSCRIBE = "subscribe_twitch",
}

export enum AccountType {
  EMAIL = "email",
}

export enum SanityCategory {
  BLOG = "blog",
  STREAMERONBOARDING = "streamer-onboarding",
}

export enum SanitySearchKeys {
  CATEGORY = "category",
  SLUG = "slug",
  TITLE = "title",
}

export enum BetStatus {
  BETTING_OPEN = "betting_open",
  DRAFT = "draft",
  BETTING_CLOSED = "betting_closed",
  OUTCOME_SET = "outcome_set",
  ACTIVE = "active",
  SETTLED = "settled",
}
